import React from "react";
import PortfolioPopupCarousel from "./PortfolioPopupCarousel";
import styles from "./portfolio-popup.module.css";
// import Image from "next/image";
import Clip from "./common/Clip";
// import Link from "next/link";
import { Link } from "react-router-dom";

const PortfolioPopup = (props) => {
  const { portfolioPopupData } = props;

  return (
    <div
      className={`bg-white dark:bg-slate-800 ${styles.portfolio_popup_inner_container}`}
    >
      <div className={`bg-slate-900 ${styles.portfolio_popup_header}`}>
        <div className={`${styles.portfolio_popup_title}`}>
          {portfolioPopupData?.projectLogo ? (
            <img
              className={`${styles.portfolio_popup_project_logo}`}
              src={portfolioPopupData?.projectLogo}
              alt=""
            />
          ) : (
            portfolioPopupData?.title
          )}
        </div>
      </div>
      <div className={`${styles.portfolio_popup_body}`}>
        <PortfolioPopupCarousel carouselData={portfolioPopupData?.carousel} />
        {portfolioPopupData?.companyBuiltWith && (
          <div className={`${styles.portfolio_section_control}`}>
            <div
              // className={`${styles.portfolio_popup_content}`}
              className="text-slate-400 text-[15px]"
            >
              <span>{portfolioPopupData?.title} </span> was build with{" "}
              {portfolioPopupData?.companyBuiltWith} Team
            </div>
          </div>
        )}
        <div className={`${styles.portfolio_section_control}`}>
          <div
            // className={`${styles.portfolio_popup_content_title}`}
            className="title h5 text-[17px] font-medium"
          >
            About {portfolioPopupData?.title}{" "}
          </div>
          <div
            // className={`${styles.portfolio_popup_content}`}
            className="text-slate-400 mt-2 text-[15px]"
          >
            {portfolioPopupData?.desc}
            {portfolioPopupData?.moreInfoLink && (
              <span>
                {" "}
                To know more about it{" "}
                <Link
                  // href={portfolioPopupData?.moreInfoLink}
                  target="_blank"
                  to={portfolioPopupData?.moreInfoLink}
                  // rel="noopener noreferrer"
                >
                  <span className="text-amber-500 text-[17px] font-medium">
                    click here{" "}
                  </span>
                </Link>{" "}
              </span>
            )}
          </div>
        </div>
        {portfolioPopupData?.whatIdid && (
          <div className={`${styles.portfolio_section_control}`}>
            <div
              className="title h5 text-[17px] font-medium"
              // className={`${styles.portfolio_popup_content_title}`}
            >
              What I did?
            </div>
            <div
              // className={`${styles.portfolio_popup_content}`}
              className="text-slate-400  mt-2 text-[15px]"
            >
              {portfolioPopupData?.whatIdid}
            </div>
          </div>
        )}

        <div className={`${styles.portfolio_section_control}`}>
          <div
            className="title h5 text-[17px] font-medium"
            // className={`${styles.portfolio_popup_content_title}`}
          >
            Skills Used
          </div>
          <div className={`${styles.portfolio_popup_skills_container}`}>
            {portfolioPopupData?.skills.map((item, index) => (
              <Clip value={item} key={item} />
            ))}
          </div>
        </div>
        {portfolioPopupData?.link && (
          <div className={`${styles.portfolio_section_control}`}>
            <div className={`${styles.portfolio_popup_content}`}>
              Project Link:{" "}
              <a
                className={`hover:text-amber-500 ${styles.link}`}
                href={portfolioPopupData?.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {portfolioPopupData?.link}
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PortfolioPopup;
