import React, { useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar";
import HeroOne from "../components/HeroOne";
// import AboutUs from "../components/AboutUs";
import Services from "../components/Service";
// import CTABanner from "../components/CTABanner";
import Experience from "../components/Experience";
import Projects from "../components/Projects";
// import Blogs from "../components/Blogs";
import GetInTouch from "../components/GetInTouch";
import Footer from "../components/Footer";
// import Clients from "../components/Clients";
import portfolioData from "../helper/portfolioData";
import Popup from "../components/common/popup/Popup";
import PortfolioPopup from "../components/PortfolioPopup";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

export default function Index(props) {
  const { direction } = props;
  const [portfolioPopupShow, setPortfolioPopupShow] = useState(false);
  const handlePortfolioPopup = () => {
    setPortfolioPopupShow((prev) => !prev);
  };
  const [portfolioPopupData, setPortfolioPopupData] = useState();
  const handlePortfolioPopupData = (data) => {
    setPortfolioPopupData(data);
  };

  const portfolioPopup = useRef();

  useEffect(() => {
    if (portfolioPopupShow) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [portfolioPopupShow]);

  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
  }, []);

  return (
    <>
      <Navbar />
      <HeroOne direction={direction} />
      <Services />
      <Experience />
      <div ref={portfolioPopup}>
        {portfolioPopupShow && (
          <Popup handlePopup={handlePortfolioPopup}>
            <PortfolioPopup portfolioPopupData={portfolioPopupData} />
          </Popup>
        )}
      </div>
      <Projects
        portfolio={portfolioData}
        handlePortfolioPopup={handlePortfolioPopup}
        handlePortfolioPopupData={handlePortfolioPopupData}
      />
      <GetInTouch />
      <Footer />
      <Analytics />
      <SpeedInsights />

      {/* <AboutUs /> */}
      {/* <CTABanner /> */}
      {/* <Clients/>   */}
      {/* <Blogs/> */}
    </>
  );
}
