import React from "react";
import { Link as Link2 } from "react-router-dom";

export default function Projects(props) {
  const { portfolio, handlePortfolioPopupData, handlePortfolioPopup } = props;

  const handlePopup = (item) => {
    handlePortfolioPopupData(item);
    handlePortfolioPopup();
  };

  return (
    <>
      <section className="relative md:py-24 py-16" id="project">
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-6 md:text-2xl text-xl md:leading-normal leading-normal font-semibold">
              My Work & Projects
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto text-[15px]">
              Explore My Works and Projects, Where Every Line of Code Tells a
              Unique Story of Innovation.
            </p>
          </div>

          <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 mt-8 gap-[30px]">
            {portfolio?.map((item, index) => {
              return (
                <div
                  onClick={() => handlePopup(item)}
                  key={index}
                  className="relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800"
                >
                  <img
                    src={item?.image}
                    alt=""
                    style={{ height: 180, width: 380 }}
                    className="w-full"
                  />
                  <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                  <div className="absolute bottom-0 opacity-0 group-hover:opacity-100 m-6 transition-all duration-500">
                    <Link2
                      to="#!"
                      className="text-white hover:text-amber-500 font-semibold transition-all duration-500"
                    >
                      {item.title}
                    </Link2>
                    <span className="block text-ssm text-slate-400">
                      Build with <span>{item.companyBuiltWith}</span> team
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setActiveIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setActiveIndex((photoIndex + 1) % images.length)
          }
        />
      )} */}
    </>
  );
}
