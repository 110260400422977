const portfolioData = [
  {
    id: 1,
    title: "Bibimando",
    companyBuiltWith: "Adlerware",
    projectColor: "#5555DB",
    desc: "Bibimando is a mobile app for an Italian Restaurant. It uses features for customer to place an order. It has online payment system as well as multiple features for the user to order food at home.",
    whatIdid:
      "I was responsible for building front-end as well played significant role for connecting google maps APIs. I created screens with dynamic features for validation, Stripe Payment System, API's integration, languages API and Google Map integration. I used react native in frontend",

    link: "https://play.google.com/store/apps/details?id=com.foodbooking.bibimando",
    // projectLogo: require("../assets/images/portfolio/fitness/fitnesslogo.jpg")
    //   .default,
    image: require("../assets/images/portfolio/bibimando/cover.png"),
    status: "live",
    carousel: [
      {
        id: 1,
        img: require("../assets/images/portfolio/bibimando/cover.png"),
        desc: "Main",
        title: "Bibimando",
      },
      {
        id: 2,
        img: require("../assets/images/portfolio/bibimando/bibimando2.png"),
        desc: "Why Bibimando",
        title: "Bibimando",
      },
    ],
    skills: [
      "Node.js",
      "Express",
      "Firebase",
      "Javascript",
      "React Native",
      "Redux",
    ],
  },
  {
    id: 1,
    title: "Mycancha",
    companyBuiltWith: "Adlerware",
    projectColor: "#5555DB",
    desc: "Mycancha is an application for organizing football and basketball matches. It uses features for players to create a scheduled match for other players to join. It allows organizers to create group chat and discuss the match features for their live match.",
    whatIdid:
      "I was responsible for building backend of this application. I used Node.js and firebase to build the server side of this application so that users can upload their match and stay organized. I also created the chat feature of this app on both client and server side.",

    link: "https://play.google.com/store/apps/details?id=com.adlerware.mycancha",
    // projectLogo: require("../assets/images/portfolio/fitness/fitnesslogo.jpg")
    //   .default,
    image: require("../assets/images/portfolio/mycancha/cover.png"),
    status: "live",
    carousel: [
      {
        id: 1,
        img: require("../assets/images/portfolio/mycancha/cover.png"),
        desc: "Main",
        title: "Mycancha",
      },
      {
        id: 2,
        img: require("../assets/images/portfolio/mycancha/mycancha2.png"),
        desc: "Why Mycancha",
        title: "Mycancha",
      },
    ],
    skills: [
      "React Native",
      "Javascript",
      "Axios",
      "Node.js",
      "Stripe",
      "Express",
      "Firebase",
    ],
  },
  {
    id: 1,
    title: "Fitness Directory",
    companyBuiltWith: "Adlerware",
    projectColor: "#5555DB",
    desc: "Fitness Directory is an application to provide fitness recruitment, fitness courses and fitness clothing.",
    whatIdid:
      "I was responsible for building front-end. I created screens with dynamic features for validation and authentication, API integration and real-time chat. I used react native on front-end and redux for state-management and socket.io for real-time chat.",

    link: "https://play.google.com/store/apps/details?id=com.adlerware.Fitness",
    // projectLogo: require("../assets/images/portfolio/fitness/fitnesslogo.jpg")
    //   .default,
    image: require("../assets/images/portfolio/fitness/cover.png"),
    status: "live",
    carousel: [
      {
        id: 1,
        img: require("../assets/images/portfolio/fitness/cover.png"),
        desc: "Main",
        title: "Fitness",
      },
      {
        id: 2,
        img: require("../assets/images/portfolio/fitness/fitness2.png"),
        desc: "Why Fitness Directory",
        title: "Fitness",
      },
      // {
      //   id: 3,
      //   img: require("../assets/images/portfolio/fitness/fitness3.png"),
      //   desc: "How It Works",
      //   title: "Fitness",
      // },
    ],
    skills: [
      "React Native",
      "Javascript",
      "Redux",
      "Socket.io",
      "Node.js",
      "Express",
      "Firebase",
    ],
  },

  {
    id: 1,
    title: "Cardo",
    companyBuiltWith: "Personal Project",
    projectColor: "#5555DB",
    desc: "Cardo is a platform for knowledge sharing applied to the labor market. It brings together consultants from top American companies who, from the app, work as mentors for professionals who would otherwise not have access to such specialized and high-end guidance.",
    whatIdid:
      "I was responsible for front-end. I created landing pages, dashboards, sign-up/login with LinkedIn, scheduling, jotform integration, API integration and real-time chat. I used ReactJS on front-end and redux for state-management and socket.io for real-time chat.",

    link: "https://www.hellocardo.org/",
    moreInfoLink: "https://www.carthagos.com/projects/Cardo",
    projectLogo: require("../assets/images/portfolio/cardo/logo2.svg").default,
    image: require("../assets/images/portfolio/cardo/cover.png"),
    status: "live",
    carousel: [
      {
        id: 1,
        img: require("../assets/images/portfolio/cardo/cardo1.png"),
        desc: "Main",
        title: "Cardo",
      },
      {
        id: 2,
        img: require("../assets/images/portfolio/cardo/cardo2.png"),
        desc: "Why Cardo",
        title: "Cardo",
      },
      {
        id: 3,
        img: require("../assets/images/portfolio/cardo/cardo3.png"),
        desc: "How It Works",
        title: "Cardo",
      },
    ],
    skills: [
      "HTML/CSS",
      "Javascript",
      "ReactJS",
      "Redux",
      "Web Sockets",
      "Calendly",
      "Express",
      "MongoDB",
    ],
  },
  {
    id: 2,
    title: "Luminous",
    companyBuiltWith: "Personal Project",
    projectColor: "#FF5607",
    desc: "Luminous is a service that lets property owners rent out their spaces to travelers looking for a place to stay. Travelers can rent a space for multiple people to share, a shared space with private rooms, or the entire property for themselves.",
    whatIdid:
      "I created booking system with user auth (JWT), scheduling system, review, dashboard, calendar and payment integration on backend. and on front-end converted Figma to html, multistep form, private routing, user and owner dashboard. we used EJS for it first, and later we converted the whole frontend to ReactJS as per clients demand.",
    link: "https://www.itsluminous.ca/",
    // moreInfoLink: "https://www.carthagos.com/projects/Cardo",
    projectLogo: require("../assets/images/portfolio/luminous/logo2.svg")
      .default,
    image: require("../assets/images/portfolio/luminous/cover.png"),
    status: "live",
    carousel: [
      {
        id: 1,
        img: require("../assets/images/portfolio/luminous/luminous1.png"),
        desc: "Main",
        title: "Luminous",
      },
      {
        id: 2,
        img: require("../assets/images/portfolio/luminous/luminous2.png"),
        desc: "Why Luminous",
        title: "Luminous",
      },
      {
        id: 3,
        img: require("../assets/images/portfolio/luminous/luminous3.png"),
        desc: "How It Works",
        title: "Luminous",
      },
    ],
    skills: [
      "HTML/CSS",
      "Javascript",
      "ReactJS",
      "Redux",
      "Express",
      "Stripe",
      "Sequalize",
      "MySQL",
    ],
  },
  //   {
  //     id: 3,
  //     title: "Gathr",
  //     companyBuiltWith: "Carthagos",
  //     projectColor: "#FF5607",
  //     desc: "Gathr is a win-win platform for product feedback. It delivers a gift box to each of its subscribers according to their profiles, containing products for them to enjoy and review. Goodies are shipped freely from companies, which receive the clients’ first-hand feedback in turn.",
  //     whatIdid: "I created the landing pages of the website.",

  //     link: "https://hellogathr.com/",
  //     moreInfoLink: "https://www.carthagos.com/projects/Gathr",
  //     projectLogo: require("../assets/images/portfolio/gathr/logo2.svg").default,

  //     image: require("../assets/images/portfolio/gathr/cover.png"),
  //     status: "live",
  //     carousel: [
  //       {
  //         id: 1,
  //         img: require("../assets/images/portfolio/gathr/gathr1.png"),
  //         desc: "Main",
  //         title: "Luminous",
  //       },
  //       {
  //         id: 2,
  //         img: require("../assets/images/portfolio/gathr/gathr2.png"),
  //         desc: "Why gathr",
  //         title: "gathr",
  //       },
  //       {
  //         id: 3,
  //         img: require("../assets/images/portfolio/gathr/gathr3.png"),
  //         desc: "How It Works",
  //         title: "gathr",
  //       },
  //     ],
  //     skills: ["HTML/CSS", "Javascript", "Wordpress", "Elementor", "Swiper.js"],
  //   },
  //   {
  //     id: 4,
  //     title: "Mimoo",
  //     companyBuiltWith: "Carthagos",
  //     projectColor: "#FF5607",
  //     desc: "In Portuguese, “mimo” means a small, gentle treat, given as an act of care. But Mimoo makes it a two-way road: the platform, specialized in marketing of engagement, offers gifts for consumers in exchange for accurate audience insights for brands. Companies place products they want to test or market research, and customers get free access to products by accomplishing a series of gamified missions on the app and social media. An innovative way for brands to level up in every phase of customer experience.",
  //     whatIdid: "I created the landing page of the website.",

  //     link: "https://www.institutional.mimoo.dev/ ",
  //     moreInfoLink: "https://www.carthagos.com/projects/Mimoo",
  //     projectLogo: require("../assets/images/portfolio/mimoo/logo2.svg").default,
  //     image: require("../assets/images/portfolio/mimoo/cover2.png"),
  //     status: "live",
  //     carousel: [
  //       {
  //         id: 1,
  //         img: require("../assets/images/portfolio/mimoo/mimoo1.png"),
  //         desc: "Main",
  //         title: "Luminous",
  //       },
  //       {
  //         id: 2,
  //         img: require("../assets/images/portfolio/mimoo/mimoo2.png"),
  //         desc: "Why mimoo",
  //         title: "mimoo",
  //       },
  //       {
  //         id: 3,
  //         img: require("../assets/images/portfolio/mimoo/mimoo3.png"),
  //         desc: "How It Works",
  //         title: "gathr",
  //       },
  //     ],
  //     skills: ["HTML/CSS", "Javascript", "Wordpress", "Elementor", "Swiper.js"],
  //   },
  //   {
  //     id: 5,
  //     title: "Nexara Robotics",
  //     companyBuiltWith: "Carthagos",
  //     projectColor: "#FF5607",
  //     desc: "Combining high-end software and hardware engineering, Nexera brings Neuragrasp: a handy solution for robotic manipulation and grasping, able to reach any type of product, material, and surface. Firmness, grip, and flexibility are a promise from Nexera’s pioneering technology.",
  //     whatIdid: "I created the landing page of the website.",

  //     link: "https://nexera-robotics.com/",
  //     moreInfoLink: "https://www.carthagos.com/projects/Nexera%20Robotics",
  //     projectLogo: require("../assets/images/portfolio/nexera/logo.svg").default,
  //     image: require("../assets/images/portfolio/nexera/cover2.png"),
  //     status: "live",
  //     carousel: [
  //       {
  //         id: 1,
  //         img: require("../assets/images/portfolio/nexera/nexera1.png"),
  //         desc: "Main",
  //         title: "Luminous",
  //       },
  //       {
  //         id: 2,
  //         img: require("../assets/images/portfolio/nexera/nexera2.png"),
  //         desc: "Why nexera",
  //         title: "nexera",
  //       },
  //       {
  //         id: 3,
  //         img: require("../assets/images/portfolio/nexera/nexera3.png"),
  //         desc: "How It Works",
  //         title: "gathr",
  //       },
  //     ],
  //     skills: ["HTML/CSS", "Javascript", "Wordpress", "Elementor", "Swiper.js"],
  //   },
  //   {
  //     id: 6,
  //     title: "ScoutX",
  //     companyBuiltWith: "Carthagos",
  //     projectColor: "#FF5607",
  //     desc: "Grounded on a triad of AI, social networks, and gig economy, ScoutX is the ultimate answer to modern sports scouting. Scouts and athletes schedule their meeting matches through the app, and ScoutX-certified professionals review the talents’ performance alongside giving them continuous and accurate feedback. By helping athletes reach higher stages of competitions, the company makes the sports market more accessible and fairer, bringing new names to the spotlight and enriching teams globally.",
  //     whatIdid: "I created the landing page of the website.",

  //     link: "https://scoutxsports.com/",
  //     moreInfoLink: "https://www.carthagos.com/projects/ScoutX",
  //     projectLogo: require("../assets/images/portfolio/scoutx/logo.svg").default,
  //     image: require("../assets/images/portfolio/scoutx/cover2.png"),
  //     status: "live",
  //     carousel: [
  //       {
  //         id: 1,
  //         img: require("../assets/images/portfolio/scoutx/scoutx1.png"),
  //         desc: "Main",
  //         title: "Luminous",
  //       },
  //       {
  //         id: 2,
  //         img: require("../assets/images/portfolio/scoutx/scoutx2.png"),
  //         desc: "Why scoutx",
  //         title: "scoutx",
  //       },
  //       {
  //         id: 3,
  //         img: require("../assets/images/portfolio/scoutx/scoutx3.png"),
  //         desc: "How It Works",
  //         title: "gathr",
  //       },
  //     ],
  //     skills: ["HTML/CSS", "Javascript", "Wordpress", "Elementor", "Swiper.js"],
  //   },
  {
    id: 7,
    title: "Hassan Store",
    desc: "E-Commerce Shopping Web App, where user can buy new or refurbished mobile phones in Pakistan",
    companyBuiltWith: "HSNA",

    link: "https://www.hassanstore.com/",
    // whatIdid: "I created the landing page of the website.",

    image: require("../assets/images/portfolio/hassan-store/HassanStore.JPG"),
    status: "live",
    carousel: [
      {
        id: 1,
        img: require("../assets/images/portfolio/hassan-store/HassanStore.JPG"),
        desc: "Home Page",
        title: "hassanstore",
      },
      {
        id: 2,
        img: require("../assets/images/portfolio/hassan-store/PhoneCategory.JPG"),
        desc: "Phone Category",
        title: "hassanstore",
      },
      {
        id: 3,
        img: require("../assets/images/portfolio/hassan-store/PhonePage.JPG"),
        desc: "Phone Page",
        title: "hassanstore",
      },
      {
        id: 4,
        img: require("../assets/images/portfolio/hassan-store/Cart.JPG"),
        desc: "Cart",
        title: "hassanstore",
      },
    ],
    skills: ["HTML/CSS", "Javascript", "Wordpress", "WooCommerce", "Ecommerce"],
  },
  {
    id: 8,
    title: "HSNA Limited",
    companyBuiltWith: "HSNA",

    desc: "Company Website of HSNATech",
    whatIdid:
      "I created all the website dynamically, with admin dashboard. Where admin was able to update the content and portfolio of the website.",

    link: "https://www.hsnatech.com/",
    image: require("../assets/images/portfolio/hsna-tech/HSNA.JPG"),
    status: "live",
    carousel: [
      {
        id: 1,
        img: require("../assets/images/portfolio/hsna-tech/HSNA.JPG"),
        desc: "Home Page",
        title: "HSNA",
      },
      {
        id: 2,
        img: require("../assets/images/portfolio/hsna-tech/HSNA1.JPG"),
        desc: "Services",
        title: "HSNA",
      },
      {
        id: 3,
        img: require("../assets/images/portfolio/hsna-tech/HSNA2.JPG"),
        desc: "Portfolio",
        title: "HSNA",
      },
    ],
    skills: [
      "HTML/CSS",
      "Javascript",
      "Bootstrap",
      "JQuery",
      "Ajax",
      "PHP",
      "MySQL",
    ],
  },
  {
    id: 9,
    title: "Tripifyy",
    desc: "It is a platform for tourist and trip organizer. where organizer can select services like hotel, transport, restaurant etc and create a trip and tourist can join trip. They can also interact via post and comments if tourist have any queries. tourist will be able to rate after the trip is complete.",
    companyBuiltWith: "Personal Project",
    image: require("../assets/images/portfolio/tripifyy/Home.JPG"),
    status: "underdevelopment",
    carousel: [
      {
        id: 1,
        img: require("../assets/images/portfolio/tripifyy/Home.JPG"),
        desc: "Home Page",
        title: "Tripifyy",
      },
      {
        id: 2,
        img: require("../assets/images/portfolio/tripifyy/4.JPG"),
        desc: "User Home",
        title: "Tripifyy",
      },
      {
        id: 3,
        img: require("../assets/images/portfolio/tripifyy/Capture.JPG"),
        desc: "Organizer Home",
        title: "Tripifyy",
      },
    ],
    skills: [
      "HTML/CSS",
      "Javascript",
      "Bootstrap",
      "ReactJS",
      "Redux",
      "Web Sockets",
      "Express",
      "Node.js",
      "MongoDB",
    ],
  },
];

export default portfolioData;
