import React, { useState } from "react";
import styles from "./clip.module.css";
import check_dark from "../../../assets/icons/check-dark.svg";
// import Image from "next/image";
import useWindowSize from "../../hooks/useWindowSize";

const level = ["Beginner", "Intermediate", "Expert"];

const Clip = (props) => {
  const { value, skill, clipMode } = props;
  const [proficiency, setProficiency] = useState(false);
  const size = useWindowSize();

  const handleClick = () => {
    if (clipMode && !proficiency) {
      setProficiency(true);
      props.toggleClip(false);
    } else if (!clipMode && proficiency) {
      props.toggleClip(true);
      setProficiency(false);
    }
  };

  return (
    <div className={`hover_size ${skill && styles.skills_hover}`}>
      <div className={`bg-gray-200 dark:bg-slate-900  ${styles.clip}`}>
        <p className={`${styles.clip_text}`}>{value}</p>
        {size < 1024 && skill && (
          <img
            src={""}
            alt="down arrow icon"
            className={`${styles.clip_icon}`}
            onClick={() => {
              handleClick();
            }}
          />
        )}
      </div>
      {skill && size > 1023 && <Proficiency skill={skill} />}

      {skill && proficiency && <Proficiency skill={skill} />}
    </div>
  );
};

const Proficiency = (props) => {
  const { skill } = props;
  return (
    <div className={`${styles.skills_container}`}>
      <p>Proficiency level</p>
      <div>
        {level.map((item) => (
          <div className={`${styles.clip_inner_container}`} key={item}>
            <p>{item}</p>
            {item === skill && <img src={check_dark} alt="check icon" />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Clip;
