import * as Icon from "react-feather";
export const expertiseData = [
  {
    title: "Coding",
    Icon: Icon.Monitor,
  },
  {
    title: "Android",
    Icon: Icon.Smartphone,
  },
  {
    title: "Mac OS",
    Icon: Icon.StopCircle,
  },
  {
    title: "Designing",
    Icon: Icon.Box,
  },
  {
    title: "Coffee",
    Icon: Icon.Coffee,
  },
  {
    title: "Movies",
    Icon: Icon.Video,
  },

  {
    title: "Music",
    Icon: Icon.Music,
  },
  {
    title: "Games",
    Icon: Icon.Watch,
  },

  // {
  //     title:'Sports',
  //     Icon: Icon.Target
  // },
  // {
  //     title:'Painting',
  //     Icon: Icon.PenTool
  // },
  // {
  //     title:'Reading',
  //     Icon: Icon.Book
  // },

  // {
  //     title:'Other Activity',
  //     Icon: Icon.Activity
  // },
];
export const servicesData = [
  {
    title: "Mobile App Development",
    desc: "As a React Native developer for iOS and Android, I specialize in creating dynamic, responsive mobile applications. I design interactive interfaces for a seamless user experience, ensuring a visually appealing journey across all platforms.",
    Icon: Icon.Smartphone,
  },
  {
    title: "Web Development",
    desc: "Specializing in full-stack development for dynamic and responsive websites. I create interactive, visually appealing interfaces, ensuring a seamless user journey.",
    Icon: Icon.Monitor,
  },
  {
    title: "Node.js Development",
    desc: "Harness the power of Node.js for robust server-side applications. I deliver cutting-edge solutions tailored to your needs, from APIs to scalable network applications.",
    Icon: Icon.Code,
  },
  {
    title: "Code Optimization",
    desc: "Improve efficiency and speed with code optimization services. Enhance performance and reduce load times for smoother application experiences.",
    Icon: Icon.Settings,
  },
  {
    title: "Consultation and Collaboration",
    desc: "Receive guidance on technology stacks or collaborate with your team. My consultation services ensure your projects align with best practices and industry standards.",
    Icon: Icon.Users,
  },
  {
    title: "Custom Solutions",
    desc: "Tailored solutions designed to meet your specific requirements. I work closely with clients, understanding unique needs to deliver customized software solutions.",
    Icon: Icon.Globe,
  },
];
export const project = [
  {
    image: "/images/works/1.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/2.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/3.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/4.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/5.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/6.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/7.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/8.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
];
export const blogData = [
  {
    image: "/images/blog/01_giglink.jpg",
    title: "Giglink: Tailwind CSS NFT Marketplace Template",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    image: "/images/blog/01_techwind.jpg",
    title: "Techwind: Tailwind CSS Multipurpose Template",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    image: "/images/blog/01_upwind.jpg",
    title: "Upwind: Tailwind CSS Landing Page Template",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
];
export const review = [
  {
    name: "Abraham Aipe",
    designation: "Music Innovator",
    image: "/images/client/01.jpg",
    desc: "Hassan seamlessly brought our app vision to life with React Native expertise. Effortless collaboration, attention to detail, and a stellar end result. Highly recommended!",
  },
  {
    name: "Abraham Aipe",
    designation: "Music Innovator",
    image: "/images/client/01.jpg",
    desc: "Hassan seamlessly brought our app vision to life with React Native expertise. Effortless collaboration, attention to detail, and a stellar end result. Highly recommended!",
  },
  {
    name: "Abraham Aipe",
    designation: "Music Innovator",
    image: "/images/client/01.jpg",
    desc: "Hassan seamlessly brought our app vision to life with React Native expertise. Effortless collaboration, attention to detail, and a stellar end result. Highly recommended!",
  },
  {
    name: "Abraham Aipe",
    designation: "Music Innovator",
    image: "/images/client/01.jpg",
    desc: "Hassan seamlessly brought our app vision to life with React Native expertise. Effortless collaboration, attention to detail, and a stellar end result. Highly recommended!",
  },
];
