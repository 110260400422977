import React, { useState } from "react";
import * as Unicons from "@iconscout/react-unicons";
import { Link } from "react-router-dom";
import styles from "./portfolio-popup.module.css";

export default function GetInTouch() {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const handleChange = (key, e) => {
    setFormData((prev) => ({ ...prev, [key]: e.target.value }));
  };

  const validateEmail = (email) => {
    // Simple email validation logic (you can use a more sophisticated method).
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!Object.values(formData).every((item) => item)) {
      alert("Enter All Fields.");
      return;
    }
    // Your additional logic here (e.g., validate the email).
    if (!validateEmail(formData?.email)) {
      alert("Please enter a valid email address.");
      return;
    }
    setLoading(true);

    try {
      const response = await fetch("https://formspree.io/f/myyryvkv", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      setLoading(false);

      if (response.ok) {
        alert("Your email has been sent successfully!");
        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      } else {
        alert("Failed to send email. Please try again later.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Failed to send email. Please try again later.");
    }
  };

  return (
    <section
      className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800"
      id="contact"
    >
      <div className="container">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h3 className="mb-6 md:text-2xl text-xl md:leading-normal leading-normal font-semibold">
            Get In Touch !
          </h3>

          <p className="text-slate-400 max-w-xl mx-auto text-[15px]">
            Let's Connect, Let's Create - Reach Out and Ignite Possibilities!
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-[30px]">
          <div className="lg:col-span-8">
            <div className="p-6 rounded-md shadow bg-white dark:bg-slate-900">
              <form onSubmit={handleSubmit}>
                <div className="grid lg:grid-cols-12 lg:gap-5">
                  <div className="lg:col-span-6 mb-5">
                    <input
                      name="name"
                      id="name"
                      type="text"
                      className="form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-10 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]"
                      placeholder="Name"
                      onChange={(e) => handleChange("name", e)}
                      value={formData?.name}
                    />
                  </div>

                  <div className="lg:col-span-6 mb-5">
                    <input
                      name="email"
                      id="email"
                      type="email"
                      className="form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-10 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]"
                      placeholder="Email"
                      onChange={(e) => handleChange("email", e)}
                      value={formData?.email}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1">
                  <div className="mb-5">
                    <input
                      name="subject"
                      id="subject"
                      className="form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-10 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]"
                      placeholder="Subject"
                      onChange={(e) => handleChange("subject", e)}
                      value={formData?.subject}
                    />
                  </div>

                  <div className="mb-5">
                    <textarea
                      name="comments"
                      id="comments"
                      className="form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-28 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]"
                      placeholder="Message"
                      onChange={(e) => handleChange("message", e)}
                      value={formData?.message}
                    ></textarea>
                  </div>
                </div>
                <button
                  disabled={loading}
                  type="submit"
                  id="submit"
                  name="send"
                  className="btn bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600 text-white rounded-md h-11 justify-center flex items-center"
                >
                  {/* Send Message */}
                  {loading && <div className={styles.loader}></div>}
                  <p style={{ color: loading ? "transparent" : "#fff" }}>
                    Send Message
                  </p>
                </button>
              </form>
            </div>
          </div>

          <div className="lg:col-span-4">
            <div className="lg:ms-8">
              <div className="flex">
                <div className="icons text-center mx-auto">
                  <Unicons.UilPhone className=" block rounded text-2xl dark:text-white mb-0" />
                </div>

                <div className="flex-1 ms-6">
                  <h5 className="text-[17px] dark:text-white mb-2 font-medium">
                    Phone
                  </h5>
                  <Link
                    to="tel:+923332926675"
                    // to="https://wa.me/923332926675"
                    className="text-slate-400 text-[15px]"
                  >
                    +92 333-292-6675
                  </Link>
                </div>
              </div>

              <div className="flex mt-4">
                <div className="icons text-center mx-auto">
                  <Unicons.UilEnvelope className=" block rounded text-2xl dark:text-white mb-0" />
                </div>

                <div className="flex-1 ms-6">
                  <h5 className="text-[17px] dark:text-white mb-2 font-medium">
                    Email
                  </h5>
                  <Link
                    to="mailto:hassanrind101@example.com"
                    className="text-slate-400 text-[15px]"
                  >
                    hassanrind101@gmail.com
                  </Link>
                </div>
              </div>

              <div className="flex mt-4">
                <div className="icons text-center mx-auto">
                  <Unicons.UilMapMarker className=" block rounded text-2xl dark:text-white mb-0" />
                </div>

                <div className="flex-1 ms-6">
                  <h5 className="text-[17px] dark:text-white mb-2 font-medium">
                    Location
                  </h5>
                  <p className="text-slate-400 text-[15px] mb-2">
                    Hyderabad, Pakistan
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
