import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/css/tailwind.css";
import "./assets/css/materialdesignicons.min.css";
import Index from "./main/index";
import Switcher from "./components/Switcher";

function App() {
  const [direction, setDirection] = useState("ltr");
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index direction={direction} />} />
      </Routes>
      <Switcher setDirection={setDirection} />
    </BrowserRouter>
  );
}

export default App;
